<template>
	<div id="liveEdit">
		<page-live-edit :is-shop="0"></page-live-edit>
	</div>
</template>

<script>
	import PageLiveEdit from '@/components/layout/live/page-live-edit.vue'
	export default {
		components: {
			PageLiveEdit
		},
	}
</script>

<style lang="scss">

</style>
